import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import TimeLine from "./TimeLine";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import { Link } from "@material-ui/core";
import QuickSearchToolbar from "../components/QuickSearchToolbar";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  noticeImage: {
    maxWidth: "70%",
  },
  paper: {
    overflow: "hidden",
    textAlign: "center",
  },
  table: {
    width: "100%",
    backgroundColor: theme.palette.primary.lightest,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  openLink: {
    padding: theme.spacing(1.5),
    "& .MuiSvgIcon-root": {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(-0.7),
    },
  },
});

function Schedule(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography
          variant="h4"
          marked="center"
          align="center"
          className={classes.title}
          component="h2"
        >
          Schedule Updates
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TimeLine />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {" "}
            <Paper className={classes.paper} elevation={3}>
              <Link
                href="https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/pdf/12.12.24+Phase+3+schedule.pdf"
                target="_blank"
              >
                <Typography className={classes.openLink} variant="subtitle1">
                  {"Schedule Update Phase 3"}
                  <PictureAsPdfIcon />
                </Typography>
              </Link>
              <Box
                component="img"
                className={classes.noticeImage}
                alt="schedule-update-3"
                src="https://darkstarcdn.s3.us-east-2.amazonaws.com/public/pats/img/12.12.24+Phase+3+schedule_page-0001.jpg"
              />
            </Paper>
          </Grid>
        </Grid>
        <div className={classes.table}>
          <QuickSearchToolbar />
        </div>
      </Container>
    </section>
  );
}

Schedule.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Schedule);
