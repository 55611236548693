import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { DataGrid } from "@mui/x-data-grid";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createMuiTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
      borderBlockColor: theme.palette.primary.light,
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function createData(
  id,
  unitNumber,
  phaseNumber,
  inspectionDate,
  estimatedConstructionStart,
  estimatedCompletion
) {
  return {
    id,
    unitNumber,
    phaseNumber,
    inspectionDate,
    estimatedConstructionStart,
    estimatedCompletion,
  };
}

const columns = [
  { headerName: "Unit", field: "unitNumber", flex: 1 },
  { headerName: "Phase #", field: "phaseNumber", flex: 1 },
  { headerName: "Inspection Date", field: "inspectionDate", flex: 1 },
  {
    headerName: "Estimated Construction Start",
    field: "estimatedConstructionStart",
    flex: 1,
  },
  { headerName: "Estimated Completion", field: "estimatedCompletion", flex: 1 },
];

const originalRows = [
  createData(uuidv4(), 111, 1, "8/29/2023", "9/11/2023", "9/29/2023"),
  createData(uuidv4(), 211, 1, "8/29/2023", "9/11/2023", "10/13/2023"),
  createData(uuidv4(), 311, 1, "8/29/2023", "9/25/2023", "11/3/2023"),
  createData(uuidv4(), 411, 1, "8/29/2023", "10/9/2023", "11/28/2023"),
  createData(uuidv4(), 511, 1, "8/29/2023", "10/25/2023", "12/10/2023"),
  createData(uuidv4(), 611, 1, "8/29/2023", "11/13/2023", "12/22/2023"),
  createData(uuidv4(), 711, 1, "8/29/2023", "12/4/2023", "1/26/2024"),
  createData(uuidv4(), "PH7", 1, "8/29/2023", "1/2/2024", "3/8/2024"),
  createData(uuidv4(), 112, 1, "8/29/2023", "9/11/2023", "9/29/2023"),
  createData(uuidv4(), 212, 1, "8/29/2023", "9/11/2023", "10/13/2023"),
  createData(uuidv4(), 312, 1, "8/29/2023", "9/25/2023", "11/3/2023"),
  createData(uuidv4(), 412, 1, "8/29/2023", "10/9/2023", "11/28/2023"),
  createData(uuidv4(), 512, 1, "8/29/2023", "10/25/2023", "12/10/2023"),
  createData(uuidv4(), 612, 1, "8/29/2023", "11/13/2023", "12/22/2023"),
  createData(uuidv4(), 712, 1, "8/29/2023", "12/4/2023", "1/26/2024"),
  createData(uuidv4(), "PH8", 1, "8/29/2023", "1/2/2024", "3/8/2024"),
  createData(uuidv4(), 113, 1, "8/29/2023", "9/11/2023", "9/29/2023"),
  createData(uuidv4(), 213, 1, "8/29/2023", "9/11/2023", "10/13/2023"),
  createData(uuidv4(), 313, 1, "8/29/2023", "9/25/2023", "11/3/2023"),
  createData(uuidv4(), 413, 1, "8/29/2023", "10/9/2023", "11/28/2023"),
  createData(uuidv4(), 513, 1, "8/29/2023", "10/25/2023", "12/10/2023"),
  createData(uuidv4(), 613, 1, "8/29/2023", "11/13/2023", "12/22/2023"),
  createData(uuidv4(), 713, 1, "8/29/2023", "12/4/2023", "1/26/2024"),
  createData(uuidv4(), "PH9", 1, "8/29/2023", "1/2/2024", "3/8/2024"),
  createData(uuidv4(), 107, 2, "2/29/2024", "3/25/2024", "4/26/2024"),
  createData(uuidv4(), 108, 2, "2/29/2024", "3/25/2024", "4/26/2024"),
  createData(uuidv4(), 109, 2, "2/28/2024", "3/25/2024", "4/26/2024"),
  createData(uuidv4(), 110, 2, "2/28/2024", "3/25/2024", "4/26/2024"),
  createData(uuidv4(), 207, 2, "2/29/2024", "4/1/2024", "5/10/2024"),
  createData(uuidv4(), 208, 2, "2/29/2024", "4/1/2024", "5/10/2024"),
  createData(uuidv4(), 209, 2, "2/28/2024", "4/1/2024", "5/10/2024"),
  createData(uuidv4(), 210, 2, "2/28/2024", "4/1/2024", "5/10/2024"),
  createData(uuidv4(), 307, 2, "2/29/2024", "4/8/2024", "5/16/2024"),
  createData(uuidv4(), 308, 2, "2/29/2024", "4/8/2024", "5/16/2024"),
  createData(uuidv4(), 309, 2, "2/28/2024", "4/8/2024", "5/16/2024"),
  createData(uuidv4(), 310, 2, "2/28/2024", "4/8/2024", "5/16/2024"),
  createData(uuidv4(), 407, 2, "2/29/2024", "4/29/2024", "6/7/2024"),
  createData(uuidv4(), 408, 2, "2/29/2024", "4/29/2024", "6/7/2024"),
  createData(uuidv4(), 409, 2, "2/28/2024", "4/29/2024", "6/7/2024"),
  createData(uuidv4(), 410, 2, "2/28/2024", "4/29/2024", "6/7/2024"),
  createData(uuidv4(), 507, 2, "2/29/2024", "5/13/2024", "6/28/2024"),
  createData(uuidv4(), 508, 2, "2/29/2024", "5/13/2024", "6/28/2024"),
  createData(uuidv4(), 509, 2, "2/28/2024", "5/13/2024", "6/28/2024"),
  createData(uuidv4(), 510, 2, "2/28/2024", "5/13/2024", "6/28/2024"),
  createData(uuidv4(), 607, 2, "2/29/2024", "5/28/2024", "7/19/2024"),
  createData(uuidv4(), 608, 2, "2/29/2024", "5/28/2024", "7/19/2024"),
  createData(uuidv4(), 609, 2, "2/28/2024", "5/28/2024", "7/19/2024"),
  createData(uuidv4(), 610, 2, "2/28/2024", "5/28/2024", "7/19/2024"),
  createData(uuidv4(), 707, 2, "2/29/2024", "6/17/2024", "8/9/2024"),
  createData(uuidv4(), 708, 2, "2/29/2024", "6/17/2024", "8/9/2024"),
  createData(uuidv4(), 709, 2, "2/28/2024", "6/17/2024", "8/9/2024"),
  createData(uuidv4(), 710, 2, "2/28/2024", "6/17/2024", "8/9/2024"),
  createData(uuidv4(), "PH4", 2, "2/29/2024", "7/8/2024", "8/30/2024"),
  createData(uuidv4(), "PH5", 2, "2/28/2024", "7/8/2024", "8/30/2024"),
  createData(uuidv4(), "PH6", 2, "2/28/2024", "7/8/2024", "8/30/2024"),
  createData(uuidv4(), 106, 3, "7/31/2024", "8/14/2024", "9/6/2024"),
  createData(uuidv4(), 105, 3, "7/31/2024", "8/14/2024", "9/6/2024"),
  createData(uuidv4(), 104, 3, "7/31/2024", "8/14/2024", "9/6/2024"),
  createData(uuidv4(), 103, 3, "7/31/2024", "8/14/2024", "9/6/2024"),
  createData(uuidv4(), 206, 3, "7/31/2024", "8/14/2024", "9/20/2024"),
  createData(uuidv4(), 205, 3, "7/26/2024", "8/14/2024", "9/20/2024"),
  createData(uuidv4(), 204, 3, "7/31/2024", "8/14/2024", "9/20/2024"),
  createData(uuidv4(), 203, 3, "7/31/2024", "8/14/2024", "9/20/2024"),
  createData(uuidv4(), 306, 3, "8/1/2024", "8/26/2024", "10/4/2024"),
  createData(uuidv4(), 305, 3, "8/1/2024", "8/26/2024", "10/4/2024"),
  createData(uuidv4(), 304, 3, "8/1/2024", "8/26/2024", "10/4/2024"),
  createData(uuidv4(), 303, 3, "8/1/2024", "8/26/2024", "10/4/2024"),
  createData(uuidv4(), 406, 3, "8/1/2024", "9/12/2024", "10/22/2024"),
  createData(uuidv4(), 405, 3, "8/1/2024", "9/12/2024", "10/22/2024"),
  createData(uuidv4(), 404, 3, "7/29/2024", "9/12/2024", "10/22/2024"),
  createData(uuidv4(), 403, 3, "8/1/2024", "9/12/2024", "10/22/2024"),
  createData(uuidv4(), 506, 3, "8/2/2024", "10/3/2024", "11/20/2024"),
  createData(uuidv4(), 505, 3, "8/2/2024", "10/3/2024", "11/20/2024"),
  createData(uuidv4(), 504, 3, "8/2/2024", "10/3/2024", "11/20/2024"),
  createData(uuidv4(), 503, 3, "8/2/2024", "10/3/2024", "11/20/2024"),
  createData(uuidv4(), 606, 3, "8/2/2024", "1/6/2025", "1/27/2024"),
  createData(uuidv4(), 605, 3, "8/1/2024", "1/6/2025", "1/27/2024"),
  createData(uuidv4(), 604, 3, "8/2/2024", "10/30/2024", "12/20/2024"),
  createData(uuidv4(), 603, 3, "8/2/2024", "10/30/2024", "12/20/2024"),
  createData(uuidv4(), 706, 3, "7/29/2024", "1/6/2025", "2/12/2024"),
  createData(uuidv4(), 705, 3, "8/5/2024", "1/6/2025", "2/12/2024"),
  createData(uuidv4(), 704, 3, "7/29/2024", "1/6/2025", "1/27/2024"),
  createData(uuidv4(), 703, 3, "8/5/2024", "1/6/2025", "1/27/2024"),
  createData(uuidv4(), "PH3", 3, "8/5/2024", "1/23/2025", "3/10/2025"),
  createData(uuidv4(), "PH2", 3, "8/5/2024", "1/6/2025", "2/20/2024"),
  createData(uuidv4(), 102, 4, "9/11/2024", "3/21/2025", "4/10/2025"),
  createData(uuidv4(), 101, 4, "9/11/2024", "10/21/2024", "11/8/2024"),
  createData(uuidv4(), 202, 4, "9/11/2024", "3/21/2025", "4/10/2025"),
  createData(uuidv4(), 201, 4, "9/11/2024", "10/21/2024", "12/6/2024"),
  createData(uuidv4(), 302, 4, "9/11/2024", "4/8/2025", "4/29/2025"),
  createData(uuidv4(), 301, 4, "9/11/2024", "11/4/2024", "1/15/2025"),
  createData(uuidv4(), 402, 4, "9/11/2024", "4/8/2025", "5/15/2025"),
  createData(uuidv4(), 401, 4, "9/11/2024", "3/21/2025", "4/15/2025"),
  createData(uuidv4(), 502, 4, "9/12/2024", "4/25/2025", "6/3/2025"),
  createData(uuidv4(), 501, 4, "9/12/2024", "3/21/2025", "5/9/2025"),
  createData(uuidv4(), 602, 4, "9/12/2024", "5/13/2025", "6/19/2025"),
  createData(uuidv4(), 601, 4, "9/12/2024", "4/15/2025", "6/4/2025"),
  createData(uuidv4(), 702, 4, "9/12/2024", "5/30/2025", "7/8/2025"),
  createData(uuidv4(), 701, 4, "9/12/2024", "5/9/2025", "6/27/2025"),
  createData(uuidv4(), "PH1 (stack 2)", 4, "9/12/2024", "6/4/2025", "7/8/2025"),
  createData(uuidv4(), "PH1 (stack 1)", 4, "9/12/2024", "6/4/2025", "7/8/2025"),
];

function QuickSearchToolbar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search unit number…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

function QuickFilteringGrid() {
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState(originalRows);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = originalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(originalRows);
  }, [originalRows]);

  return (
    <DataGrid
      components={{ Toolbar: QuickSearchToolbar }}
      rows={rows}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[5, 10, 25]}
      columns={columns}
      disableColumnMenu
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: (event) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(""),
        },
      }}
    />
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default QuickFilteringGrid;
